 :root {
     --ping-color: rgba(255, 93, 93, 0.7);
 }

 .ping {
     background: var(--ping-color);
     border-radius: 50%;
     border: 4px solid var(--ping-color);
     width: 5px;
     height: 5px;
     animation: load 2s infinite;
     display: inline-block;
 }

 @keyframes load {
     0% {
         box-shadow: 0 0 0 0 var(--ping-color);
     }

     50% {
         box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
     }

     100% {
         box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
     }
 }