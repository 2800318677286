.ql-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 18px;
    line-height: 1;
}
.ql-tooltip{
    z-index: 99999;
}