.sticky-zone {
  .sticky-header {
    position: sticky;
    top: -1px;
    z-index: 1000;
    background-color: #f8f8f8;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .table-responsive {
    overflow: auto;
    height: 700px;
    thead th {
      box-shadow: inset 0 1px #383636;
      border-top: 1px solid #000;
    }
    thead tr:nth-child(2) th:first-child {
      position: sticky;
      left: 0;
      top: -1px;
      z-index: 3;
      background-color: #d8e2ef;
  }
    tbody td:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
      background-color:  #d8e2ef;
    }
  }
}
