// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.kpiFigures {
  color: white;
  cursor: pointer;
}

.kpiDanger {
  background-color: red !important;
  color: white;
}

.kpiWarning {
  color: white;
  background-color: #ff9800 !important;
}

.kpiSafe {
  color: white;
  background-color: #2e7d32 !important;
}

.kpiTBC {
  background-color: #35466e !important;
}

.triggeredCell {
  margin: auto !important;
}

.commment {
  p {
    margin: 0;
  }
}

.editor-parent {
  display: grid;
  grid-template-columns: 1fr;
}

// React Tag Input 
.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 5px;
  min-height: 40px;
  // min-height: unset !important;
  padding-left: 1rem;
  background-color: var(--falcon-input-bg) !important;
  border: 1px solid var(--falcon-input-border-color) !important;
  box-shadow: var(--falcon-box-shadow-inset);
}

.react-tagsinput-tag {
  border-radius: .25rem;
  background-color: var(--falcon-200) !important;
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid var(--falcon-200);
  color: #777;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 2px;
}

.react-tagsinput-tag a::before {
  content: " ×";
  color : black;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 0px;
  outline: none;
  padding: 5px;
  width: 180px;
}
// React Tag Input 
.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
}
.required::after {
  content: ' *';
  color: red;
}

#my-tooltip{
  z-index: 9999;
}

.fc-timegrid-slot-lane {
  height: 75px !important;
}
// .table-responsive {
//   overflow: auto;
//   max-height: 400px;
//   border: 1px solid red;
// }


th {
  position: sticky;
  top: 110;
}

.required::after {
  content: ' *';
  color: red;
}