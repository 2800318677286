.outer-circle {
  position: relative;
}

.circle-progress-svg {
  transform: rotate(90deg);
}

.progress-bar-rail {
  stroke-linecap: round;
}

.progress-bar-top {
  transition: stroke-dashoffset 0.5s ease-in-out;
}

.circle-progress-svg .progress-bar-rail {
  stroke-dashoffset: 72;
  stroke: var(--falcon-gray-200);
}